@font-face {
  src: url(../src/fonts/Montserrat-Regular.ttf);
  font-family: "Montserrat-Regular";
}

@font-face {
  src: url(../src/fonts/Montserrat-Bold.ttf);
  font-family: "Montserrat-Bold";
}

.loader {
  position: absolute;
  top: 40%;
  left: 47%;
}

body {
  -webkit-user-select: none;
  /* Chrome Todos / Safari Todos */
  -moz-user-select: none;
  /* Firefox Todos */
  -ms-user-select: none;
  /* IE 10+ */
  -o-user-select: none;
  user-select: none;
}

body a.btn-small:hover {
  color: white !important;
  background-color: #6d3183 !important;
}

/*#region NOVO PADRAO CSS*/
.white-Box {
  border-radius: 20px !important;
  box-shadow: none !important;
}

.div-flex {
  display: flex;
  padding: 0rem !important;

}



.coluna {
  border-radius: 10px !important;
  background-color: #e5e5e5 !important;
  color: #6c6c6c !important;
  border: white 0px solid !important;
  text-align: center;
  width: 25%;
  font-family: Montserrat-Bold;
}

.collapsible {
  border: white;
  border-style: solid;
  border-radius: 20px;
}

.collapsible li {
  border: white;
  border-style: solid;
  border-radius: 20px;
}

.checkbox-column{
  text-align: center !important;
}

.collapsible-body {
  border-bottom: 0px !important;
}

.keyboard-focused .collapsible-header:focus {
  background-color: transparent !important;
}

.collapsible-header {
  border-bottom: 0px !important;
  color: #20849A;
  font-family: Montserrat-Bold;
}

.name-File {
  text-transform: capitalize;
  text-align: center;
  color: #20849a;
  font-family: Montserrat-Bold;
}

.document-Type {
  text-align: center;
  color: #646464;
  font-family: Montserrat-Regular;
}

.status {
  border: 1px transparent solid;
  border-radius: 10px !important;
  width: 50%;
  display: block !important;
  text-align: center;
  margin: auto;
  font-family: Montserrat-Bold;
  color: white;
}

.active-Color {
  background-color: #0C7B93;
}

.cancel-color {
  background-color: #CE1E57;
}

.button-Down {
  border-radius: 50px;
  color: white;
  background-color: #0C7B93;
  outline: 3px solid #0C7B93;
  box-shadow: 0px 0px 4px 2px #0c7b93;
}

/*#endregion */

.login {
  margin-top: 70px;
  margin-bottom: 70px;
}

.sidenav {
  max-width: 240px;
}

.fit-content {
  width: fit-content !important;
  padding-right: 0 !important;
}

.navbar {
  background-color: transparent;
  height: 50px;
  padding-top: 0 !important;
  margin-top: 0 !important;
}

.header {
  border-bottom: 1px solid #ddd;
  background-color: white;
  height: 50px !important;
  padding-top: 0;
  margin-top: 0 !important;

}

/*loading*/
.progress .indeterminate {
  background-color: #6d3183;
}

/*layout snackbar download pacote*/
div.eWkWwl {
  max-width: 100% !important;
  left: 0px !important;
  background-color: rgba(76, 61, 87, 0.8);
  right: 0;
  height: 100px;
  border-radius: 0;
  animation: fadeinn 0.5s, expand 0.5s 0.5s;
  bottom: 0
}

div._bottom-toaster-transition-exit-active {
  animation: fadeinn 1s 1s, expand 0.5s 0.5s;
}

div.equdlk {
  max-width: 100% !important;
  left: 0px !important;
  background-color: rgba(76, 61, 87, 0.8);
  right: 0;
  height: 100px;
  border-radius: 0;
  bottom: 0;
  animation: fadeinne 1s 1s
}

@keyframes fadeinn {
  0% {
    bottom: -80px;
    opacity: 0;
  }

  100% {
    bottom: 0 !important;
    opacity: 1;
  }
}

@keyframes fadeinne {
  0% {
    bottom: -500px !important;
    opacity: 1;
  }

  50% {
    bottom: 150px !important;
    opacity: 0;
  }
}

@keyframes fadeoutt {
  0% {
    bottom: 0px;
    opacity: 1;
  }

  100% {
    bottom: -8000px !important;
    opacity: 0;
  }
}



.purple {
  background-color: #4d1b5f !important;
}

.purple-light {
  background-color: #6d3183 !important;
}

div.cHykBG {
  display: none;
}

/*loading*/
.spinner-blue,
.spinner-blue-only {
  border-color: white;
}

.preloader-wrapper.small {
  width: 20px;
  height: 20px;
  margin-top: 7px;
}

.circle-clipper .circle {
  border-width: 1.5px;
}


div.inAQjx {
  width: 100%;
  padding-left: 0;
}

.progress {
  background-color: #aa5ac7
}

nav .brand-logo {
  top: -7px;
}

.btn-extended {
  display: block;
  width: 80%;
  margin: auto;
  margin-top: 20px;
}

.purple.darken-3 {
  background-color: #6d3183 !important;
}

table tbody tr {
  border-bottom: 0px transparent !important;
}

.brand-logo {
  width: 157px;
  height: 25px;
  background: url(https://img.vidalink.com.br/shared/logo_vdk.svg);
  margin-top: 18px;
}

.row--margin-filtros {
  margin: 0px 0 30px 0;
}

.row--filtros-secundarios {
  background-color: #f3f3f3;
  margin: 0 0.01rem !important;
  padding: 1.4rem 0 1rem 0;
  margin-bottom: 30px !important;
  margin-top: 30px !important;
}

.card-title--gestao {
  font-family: Montserrat-Bold;
  color: #20849A;
  font-size: large !important;
}

.card-title--gestao p {
  font-size: 2.2rem !important;
  font-family: Montserrat-Regular;
  color: #646464;
  font-size: smaller !important;
}

.input-field {
  margin: 0 auto;
}

.filtros--fontsize-G {
  font-family: Montserrat-Bold;
  color: #0C7B93;
  font-size: inherit;
}



.filtros--paddingP {
  padding-top: 0.478% !important;
}

.prefix--filtros {
  font-size: 1.6rem !important;
  top: 0.3rem !important;
  left: 0.3rem !important;
  color: #bbb !important;
}

.input-field .prefix--filtros~input {
  margin-left: 2rem !important;
  height: 2.2rem !important;
}

.input--filter input {
  margin-left: 1rem !important;
  height: 2.2rem !important;
}

.input-field--secundario input {
  margin-left: 0rem !important;
  height: 1.6rem !important;
}

/* UPDATE ORIGINAL CSS FROM MATERILIZE FORMS*/
/* label color */
.input-field label {
  color: #000;
}

/* label focus color */
.input-field input[type="text"]:focus+label {
  color: #000;
}

.input-field--secundario label {
  font-size: 1rem !important;
}

/* label underline focus color */
.input-field input[type="text"]:focus {
  border-bottom: 1px solid #6d3183;
  box-shadow: 0 1px 0 0 #6d3183;
}

/* valid color */
.input-field input[type="text"].valid,
.input-field select,
.chips.focus {
  border-bottom: 1px solid #6d3183 !important;
  box-shadow: 0 1px 0 0 #6d3183 !important;
}

/* invalid color */
.input-field input[type="text"].invalid {
  border-bottom: 1px solid #6d3183;
  box-shadow: 0 1px 0 0 #6d3183;
}

/* icon prefix focus color */
.input-field .prefix.active {
  color: #000;
}

.input-field,
.dropdown-content li>a,
.dropdown-content li>span {
  color: #6d3183;
}

.ano-field .p-dropdown,
.mes-field .p-dropdown {
  min-width: 100px !important;
  border: none;
  border-bottom: 1px rgba(158, 158, 158, 1) solid;
}

.input-field--secundario .p-multiselect,
.input-field--secundario .p-dropdown {
  min-width: 220px !important;
  max-width: 250px !important;
  border: none;
  border-bottom: 1px rgba(158, 158, 158, 1) solid;
  background-color: transparent !important;
}

body .p-multiselect,
body .p-dropdown {
  min-width: 220px !important;
  max-width: 250px !important;
  border: none !important;
  border-bottom: 1px rgba(158, 158, 158, 1) solid !important;
  background-color: transparent !important;
  font-size: 1rem !important;
}

body .p-multiselect label {
  font-size: 1rem !important;
  color: #000 !important;
}

body .p-multiselect .p-multiselect-trigger {
  background-color: transparent !important;
}

body .p-inputtext {
  background-color: transparent !important;
}

body .p-dropdown .p-dropdown-trigger {
  background-color: transparent !important;
}

.ano-field,
.mes-field {
  width: fit-content !important;
  padding: 0 !important;
}

.mes-competencia {
  margin-left: 14px !important;
}

.final {
  padding-left: 20px !important;
}

.icon {
  width: 40px !important;
}

/**/

.chips {
  min-height: 0 !important;
}

.chips .input {
  width: 260px !important;
}

.col--padding-r-zero {
  padding-right: 0 !important;
}

.col--filtro-label {
  padding-top: 0px !important;
}

.col--filtro-label--secondary {
  padding-top: 5px !important;
}

.tags {
  margin-top: 20px;
}

.container nav {
  box-shadow: none !important;
}

.chip:focus,
.chip:hover,
body .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {

  color: white;
}

/*background-color: #6d3183!important;*/




nav,
section,
footer {
  /* padding-left: 250px; */
}

/* body .p-checkbox .p-checkbox-box.p-highlight,
body .p-checkbox .p-checkbox-box.p-highlight:hover {
  border-color: #4e1e5f!important;
  background-color: #4e1e5f!important;
} */

[type="checkbox"].filled-in:checked+span:not(.lever):after {
  border-color: #4e1e5f;
  background-color: #4e1e5f;
}

body .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
  border-color: #4e1e5f;
  background-color: #4e1e5f;
}

/* body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background-color: #6d3183!important;
} */

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight label {
  color: #fff !important;
}

.sidenav-trigger {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .m12--margin-bottom {
    margin-bottom: 20px !important;
  }



}

@media only screen and (max-width: 992px) {

  nav,
  section,
  footer {
    padding-left: 0;
  }
}

@media only screen and (min-width: 992px) {
  .brand-logo {
    margin-left: 18px;
    background: url(https://img.vidalink.com.br/shared/logo_vdk.svg);
  }



  .col--filtro-label,
  .col--filtro-label--secondary {
    width: fit-content !important;
  }

  .container {
    width: 100%;
    max-width: 100% !important;
    max-height: 100% !important;
    height: 100% !important;
  }
}